<template>
<div v-if="!isFinishImport" class="row m-0 p-4">
    <div class="col-12 px-0">
        <div class="d-flex justify-content-between">
            <h3>{{ $t('importContacts') }}</h3>
        </div>
        <hr class="my-4" />
    </div>
    <div class="col-12 mt-2 px-0">
    <card header-classes="bg-transparent" body-classes="p-4 row justify-content-center">
        <small class="col-12 mb-2">{{ $t('explanationCSV') }}</small>
        <div class="col-12">
            <el-upload
                class="avatar-uploader w-100 upload-csv"
                action=""
                drag
                v-model="csvFile"
                :auto-upload="false"
                :on-change="uploadFile =>changeFile(uploadFile)"
                :on-remove="uploadFile =>changeFile(null)"
                :show-file-list="true"
                :disabled="isDisabledEdit"
                accept=".csv"
                :multiple="false"
                :limit="1"
                >
                    <div
                        class="el-upload__text texto"
                    >
                        {{ $t("dropFilesHere") }}
                    </div>
            </el-upload>
            <small
                v-if="markAsInvalid( csvFile === null || csvFile === '')"
                class="text-danger"
                >{{ $t("formValidations.required") }}</small
            >
        </div>
        <div class="col-12 d-flex flex-column">
            <a class="mt-2" download="ExampleCSV" target='_blank' href="/docs/ExampleCSV.csv">{{$t('downloadCSVexample')}}</a>
            <small class="pt-2">*{{$t('infoAddedContacts')}}</small>
        </div>
    </card>
    </div>
    <div class="col-12 d-flex justify-content-around px-0">
        <base-button
        borderRadiusLeft
        borderRadiusRight
        class="py-2 px-4"
        :disabled="isLoading"
        @click="$emit('endImport', false)"
        >
            <span> {{ $t('cancel') }} </span>
        </base-button>
        <base-button
        borderRadiusLeft
        borderRadiusRight
        class="py-2 px-4 d-flex"
        :disabled="isLoading"
        @click="importCSV()"
        >
            <clip-loader v-if="isLoading" color="#ffffff" size="15px" class="pr-2"></clip-loader>
            <span > {{ $t('save') }} </span>
        </base-button>
    </div>
</div>
<div v-else class="row m-0 p-4">
    <div class="col-12 px-0">
        <div class="d-flex justify-content-center pb-2">
            <h3>{{ $tc('newAddedContacts', [contactsAdded]) }}</h3>
        </div>
    </div>
     <div class="col-12 px-0">
        <div class="col-12 d-flex justify-content-center px-0">
        <base-button
        borderRadiusLeft
        borderRadiusRight
        class="py-2 px-4"
        :disabled="isLoading"
        @click="$emit('endImport', true)"
        >{{ $t('accept') }}
        </base-button>
    </div>
    </div>
</div>
</template>

<script>
import useForms from "@/composables/useForms";
import useMails from '@/composables/useMails';
import { ElUpload } from 'element-plus';
import { ref } from '@vue/reactivity';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';


export default {
  name: "ImportCSV",
  props: [],
  components: {
    ElUpload,
    ClipLoader
  },
  emits:["endImport"],
  setup(props, {emit}) {
    const { importContacts  }= useMails();
    const { markAsInvalid, isValidForm} = useForms();
    const csvFile = ref(null)
    const isDisabledEdit = ref(false)
    const isLoading = ref(false)
    const isFinishImport = ref(false)
    const contactsAdded = ref([])


    const importCSV = async()=>{
        isLoading.value = true;

        
        isValidForm({file: csvFile.value}, ['file'])
        if(csvFile.value){
            let formData = new FormData();
            formData.append('file', csvFile.value.raw )
            let response = await importContacts(formData)
            if(response && response.status === 200){
                contactsAdded.value = response.data.length;
                isFinishImport.value = true;
            }
        }
        isLoading.value = false;

    }
    const changeFile = (file)=>{
        csvFile.value = file;
    }

    return {
        csvFile,
        markAsInvalid,
        isDisabledEdit,
        isLoading,
        isFinishImport,
        contactsAdded,
        importCSV,
        changeFile
    };
  },
};
</script>

<style scoped lang="scss">
.upload-csv{
    height: 178px;
}

</style>
