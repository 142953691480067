<template>
<div class="row m-0 p-4">
  <div class="col-12 px-0">
    <div class="d-flex justify-content-between">
      <h3>{{ $t('contactForm') }}</h3>
      </div>
    <hr class="my-4" />
  </div>
  <div class="col-12 mt-2 px-0">


  <Form
    @submit="saveContact"
    :initialValues="contact"
    @change="uploadForm($event, contact)"
    :validation-schema="schema"
  >
    <card header-classes="bg-transparent" body-classes="p-4 row">
        <div class="row">
          <div class="col-12 col-lg-6 mb-4 mr-lg-2 d-flex flex-column">
            <label for="title">{{ $t("salutation") }}:</label>
            <Field
              :disabled="isDisabledEdit"
              id="title"
              name="title"
              type="text"
              :label="$t('salutation')"
              class="input-stevie"
              :class="{
                'has-danger': markAsInvalid(
                  contact.title === null || contact.title === ''
                ),
              }"
              maxLength="25"
            ></Field>
            <ErrorMessage name="title" class="text-danger text-required-error" />
          </div>
          <div class="col-12 col-lg-6 mb-4 d-flex flex-column">
            <label for="name">{{ $t("firstName") }}:</label>
            <Field
              :disabled="isDisabledEdit"
              id="name"
              name="name"
              type="text"
              :label="$t('firstName')"
              class="input-stevie"
              :class="{
                'has-danger': markAsInvalid(
                  contact.name === null || contact.name === ''
                ),
              }"
              maxLength="255"
            ></Field>
            <ErrorMessage name="name" class="text-danger text-required-error" />
          </div>
          <div class="col-12 col-lg-6 mb-4 d-flex flex-column">
            <label for="name">{{ $t("lastName") }}:</label>
            <Field
              :disabled="isDisabledEdit"
              id="lastname"
              name="lastname"
              type="text"
              :label="$t('lastName')"
              class="input-stevie"
              :class="{
                'has-danger': markAsInvalid(
                  contact.lastname === null || contact.lastname === ''
                ),
              }"
              maxLength="255"
            ></Field>
            <ErrorMessage name="lastname" class="text-danger text-required-error" />
          </div>
          <div class="col-12 mb-4 d-flex flex-column">
            <label for="name">{{ $t("email") }}:</label>
            <Field
              :disabled="isDisabledEdit"
              id="email"
              name="email"
              type="text"
              :label="$t('lastName')"
              class="input-stevie"
              :class="{
                'has-danger': markAsInvalid(
                  contact.email === null || contact.email === '' || !isValidEmail(contact.email)
                ),
              }"
              maxLength="255"
            ></Field>
            <ErrorMessage name="email" class="text-danger text-required-error" />
          </div>
        </div>
    </card>
    <div class="col-12 d-flex justify-content-around px-0">
      <base-button
        borderRadiusLeft
        borderRadiusRight
        class="py-2 px-4"
        :disabled="isLoading"
        @click="$emit('endForm', false)"
        >{{ $t('cancel') }}
      </base-button>
      <base-button
        borderRadiusLeft
        borderRadiusRight
        class="py-2 px-4 d-flex"
        :disabled="isLoading"
        native-type="submit"
        >
        <clip-loader v-if="isLoading" color="#ffffff" size="15px" class="pr-2"></clip-loader>
        {{ $t('save') }}
      </base-button>
    </div>
  </Form>
  </div>
</div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import useForms from "@/composables/useForms";
import { onMounted, ref } from '@vue/runtime-core';
import useMails from '@/composables/useMails';
import { ContactClass } from "@/util/Models/ContactClass.js";
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';


export default {
  name: "ContactForm",
  props: ["contactId", "isSaving"],
  components: {
    Form,
    Field,
    ErrorMessage,
    ClipLoader
  },
  emits:["endForm"],
  setup(props, {emit}) {
    const {contactsData, storeContact, updateContact  }= useMails();
    const { markAsInvalid, isValidForm, uploadForm, isValidEmail} = useForms();
    const contact = ref(new ContactClass({}))
    const isDisabledEdit = ref(false)
    const isLoading = ref(false)
    const schema = {
      title: "required",
      name: "required",
      lastname: "required",
      email: "required|email"
    }

    const saveContact = async() => {
      isLoading.value = true;
      if(!isValidForm(contact.value, Object.keys(contact.value), isValidEmail(contact.value.email))){
        isLoading.value = false;
        return
      }
      let response;
      if(props.contactId){
        response = await updateContact(contact.value, props.contactId)
      }else{
        response = await storeContact(contact.value)
      }
      if(response && response.status === 200){
        emit("endForm", true)
      }
        isLoading.value = false;
    }

    onMounted(()=>{
        if(props.contactId){
            let editContact = contactsData.value.data.find(item => item.id === props.contactId)
            contact.value = editContact ? new ContactClass(editContact) : new ContactClass({});
        }

    })


    return {
      contact,
      markAsInvalid,
      uploadForm,
      isDisabledEdit,
      isValidEmail,
      isLoading,
      saveContact,
      schema
    };
  },
};
</script>

<style scoped lang="scss"></style>
