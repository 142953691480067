<template>
      <div class="row align-items-center">
        <div class="col-12">
          <h3 class="mb-2">{{ $t("contacstList") }}</h3>
        </div>
        <div class="col-12 pb-2 pb-sm-0 d-flex flex-wrap">
            <base-button
              borderRadiusLeft
              borderRadiusRight
              class="py-2 px-3 my-1"
              @click="openModal('contactForm' ,null)"
            >
              <img class="mr-1" src="/img/icons/stevie/add-shape.svg" alt="Add icon">
              {{ $t("newContact") }}
            </base-button>
            <base-button
              borderRadiusLeft
              borderRadiusRight
              class="py-2 px-3 my-1"
              @click="openModal('importCSV')"
            >
              <img class="mr-1" src="/img/icons/stevie/add-shape.svg" alt="Add icon">
              {{ $t("importCSV") }}
            </base-button>
            <base-button
              borderRadiusLeft
              borderRadiusRight
              class="soft-green-button py-2 px-3 my-1 ml-md-auto"
              @click="sendMail('selected')"
            >
              {{ $t("sendToSelected") }}
            </base-button>
            <base-button
              borderRadiusLeft
              borderRadiusRight
              class="soft-green-button py-2 px-3 my-1"
              @click="sendMail('all')"
            >
              {{ $t("sendToAll") }}
            </base-button>
        </div>
      </div>

    <el-table
      class="table-responsive table-flush border-top-0 min-table"
      :data="contactsData.data"
      @selection-change="handleSelectionChange"
      @cell-click="cellClick"
      row-class-name="cursor-pointer"
      ref="contactsTable"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column :label="$t('salutation')" prop="title" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <div class="media-body w-100 text-truncate">
              <span class="font-weight-500 name mb-0 text-sm text-dark">{{
                row.title
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('firstName')" prop="name" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div v-if="row.name" class="media align-items-center">
            <div class="media-body w-100">
              <span class="font-weight-500 name mb-0 text-sm text-dark">{{
                    row.name
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('lastName')" prop="lastname" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <div class="media-body w-100 text-truncate">
              <span class="font-weight-500 name mb-0 text-sm text-dark">{{
                row.lastname
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('email')" prop="email" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div class="media align-items-center">
            <div class="media-body w-100 text-truncate">
              <span class="font-weight-500 name mb-0 text-sm text-dark">{{
                row.email
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('actions')" min-width="130px" label-class-name="border-top-0 text-prussian-blue d-flex justify-content-end" align="right">
        <template v-slot="{ row }">
          <el-tooltip placement="top" :content="$t('edit')">
            <base-button class="btn-default bg-transparent border-0 shadow-none px-0" @click="openModal('contactForm', row.id)">
              <em class="fas fa-edit text-prussian-blue"></em>
            </base-button>
          </el-tooltip>
          <el-tooltip placement="top" :content="$t('delete')">
            <base-button :disabled="row.status === 4" class="btn-default bg-transparent border-0 shadow-none px-0" @click="modalDeleteContact(row.id)">
              <em class="fas fa-trash text-prussian-blue"></em>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination :modelValue="contactsData.current_page" @update:modelValue="changePage" :total="contactsData.total" :perPage="contactsData.per_page"></base-pagination>
    </div>
  <modal v-model:show="showModal" :is-allowed-close-modal="false" body-classes="p-0 border-radius-stevie bg-athens-gray modal-contacts" modal-content-classes="border-radius-stevie modal-animation">
    <ContactForm v-if="modals.contactForm" :contactId="contactIdToManage" @endForm="endModalForm"></ContactForm>
    <ImportCSV v-if="modals.importCSV" @endImport="endModalForm"></ImportCSV>
    <SendMail v-if="modals.sendMail" @endSendMail="endModalForm" :contacts="arrContactsSelected"></SendMail>

  </modal>

  <ConfirmModal
    v-if="showConfirmModal"
    :showModal="showConfirmModal"
    :modalInfo="modalConfirm"
    :isLoading ="isLoading"
    @confirm="modalConfirm.confirmAction()"
    @cancel="modalConfirm.cancelAction(null)"
    @closeModal="showConfirmModal=false"
  ></ConfirmModal>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElSelect,
  ElOption
} from "element-plus";
import { computed, onMounted, ref, shallowRef } from "vue";
import { useRoute, useRouter } from "vue-router";
import useMails from "@/composables/useMails";
import ConfirmModal from "@/views/Components/ConfirmModal";
import Modal from '@/components/Modal';
import ContactForm from './ContactForm.vue';
import ImportCSV from "./Components/ImportCSV.vue";
import SendMail from "./Components/SendMail.vue";
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import useSubscription from '@/composables/useSubscription';
import { allSubscriptions } from '@/util/Settings.js';

export default {
  name: "ContactsList",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    ElSelect,
    ElOption,
    ConfirmModal,
    ContactForm,
    Modal,
    ImportCSV,
    SendMail
},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const { t } = useI18n();

    const contactsTable = ref(null)
    const showConfirmModal = ref(false)
    const { contactsData, getContacts, cloneMail, deleteContact }= useMails();
    const { subscriptionPermissions, changeSuscriptionModal } = useSubscription()
    const allowMails = subscriptionPermissions.value.allowMails
    const contactIdToManage = ref(null)
    const isLoading = ref(false)
    const csvFile = ref(null)
    const contactsSelectedByPage = ref({})
    const sendTo = ref(null)
    const currentPageSelected= ref([])
    const arrContactsSelected = computed(()=>{
      let total = []
      Object.keys(contactsSelectedByPage.value).forEach(page=>{
        total= [...total, ...contactsSelectedByPage.value[page]]
      })
      return total
    })


    const modalConfirm = ref({
      title: null,
      subtitle: null,
      textCancel: null,
      textConfirm: null,
      confirmAction: null,
      cancelAction: null,
      allowClose:false,
    })
    const showModal= ref(false);
    const modals= shallowRef({
      importCSV: false,
      contactForm:false,
      sendMail: false
    })

    const changePage = (event)=>{

      contactsSelectedByPage.value[`page_${contactsData.value.current_page}`] = [... currentPageSelected.value]

      if(event !== contactsData.value.current_page){
        listContacts(event)
      }
    }

    const listContacts = async (page = 1) => {
      let response = await getContacts(page);
      if(Object.keys(contactsSelectedByPage.value).length > 0 && contactsSelectedByPage.value[`page_${contactsData.value.current_page}`]){

        contactsSelectedByPage.value[`page_${contactsData.value.current_page}`].forEach(contact => {

          let selectedContact = response.data.data.find(item=>item.id === contact.id)
          if(selectedContact){
            contactsTable.value.toggleRowSelection(selectedContact, true)
          }
        })
      }

    }

    const editContact = (id) => {
      router.push({ name: 'editContact', params: { id: id } });
    }
    const cloneItem = async (id) => {
      const response = await cloneMail(id)
      if (response && response.status === 200) {
        editContact(response.data.id)
      }
    }
    const modalDeleteContact = async (contactId) => {
      if(contactId){

        modalConfirm.value = {
          title:'delete',
          subtitle: 'modalDeleteContact.subtitle',
          textCancel: 'no',
          textConfirm: 'yes',
          confirmAction: (()=>{initDeleteContact(contactId)}),
          cancelAction: modalDeleteContact,
          allowClose: false,
        }
        contactIdToManage.value = contactId;
        showConfirmModal.value = true;

      }else{
        modalConfirm.value = {};
        contactIdToManage.value = null;
        showConfirmModal.value = false;
      }
    }

    const initDeleteContact = async (id) => {
      isLoading.value= true;
      const response = await deleteContact(id)
      if(response && response.status === 200){
        listContacts(contactsData.value.current_page)
      }
      isLoading.value= false;
      modalDeleteContact(null)

    }
    const sendMail = (sendOption)=>{

      if(!allowMails){
        const subscriptionRequired = allSubscriptions.find(subscription => subscription.permissions.allowMails)
        changeSuscriptionModal(true, subscriptionRequired.name)
        return
      }

      contactsSelectedByPage.value[`page_${contactsData.value.current_page}`] = [... currentPageSelected.value]

      if(sendOption === 'selected' && arrContactsSelected.value.length === 0){
        toast.warning( t("noContactSelected"), { timeout: 3000 });
        return
      }
      sendTo.value = sendOption;
      openModal('sendMail')

    }
    const handleSelectionChange = (contacts)=>{

      currentPageSelected.value= [...contacts]
    }
    const endModalForm = (updateList) => {
      if(updateList){
        listContacts();
        contactsTable.value.clearSelection()
        contactsSelectedByPage.value = {}
      }
      contactIdToManage.value = null;
      showModal.value = false
      Object.keys(modals.value).forEach( field => {
        modals.value[field]= false;
      })
    }
     const openModal = async(modalName, id)=>{
      showModal.value=true;
      contactIdToManage.value = id ? id : null;
      modals.value[modalName] = true;
    }

    const cellClick = ({id: contactId}, { property: columnName }) => {
      columnName && openModal('contactForm', contactId);
    }

    onMounted(()=>{
      const { page, filter_status } = route.query;
      contactsData.value.current_page = page ? page : 1;
      if(page || filter_status){
        statusSelected.value = filter_status ? filter_status : 'active';
        router.replace({...router.currentRoute, query: {}})
      }
        listContacts(contactsData.value.current_page)
    })

    return {
      showConfirmModal,
      modalConfirm,
      modalDeleteContact,
      cloneItem,
      initDeleteContact,
      changePage,
      sendTo,
      arrContactsSelected,
      contactsSelectedByPage,
      modals,
      openModal,
      csvFile,
      contactIdToManage,
      endModalForm,
      showModal,
      isLoading,
      contactsData,
      listContacts,
      sendMail,
      handleSelectionChange,
      allowMails,
      contactsTable,
      cellClick,
    };
  },
}
</script>

<style scoped lang="scss">
.el-table_1_column_4 th {
  text-align: right;
}
:deep() .el-table__body-wrapper{
  min-height: 350px!important;
}
.min-table{
  min-height: 450px;
  max-width: 99%!important;
}
.dropdown-item{
  height: 33px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover{
    color: #16181b!important;
    background-color: #f6f9fc!important;
  }
}

</style>
