<template>
<div v-if="!isSent" class="row m-0 p-4">
    <div class="col-12 px-0">
        <div class="d-flex justify-content-between">
            <h3>{{ $t('sendMail') }}</h3>
        </div>
        <hr class="my-4" />
    </div>
    <div class="col-12 mt-2 px-0">
        <card header-classes="bg-transparent" body-classes="p-4 row justify-content-center">
            <div v-if="stepSendMail === 1" class="col-12 d-flex flex-column">
                <base-input class="" name="">
                    <el-autocomplete
                    v-model="queryAutocomplete"
                    :fetch-suggestions="querySearch"
                    popper-class="my-autocomplete"
                    clearable
                    :placeholder="$t('selectTemplate')"
                    @select="selectTemplate"
                    @change="selectTemplate"
                    value-key="name"
                    :disabled="isLoading"
                    class="autocomplete-input"
                >
                        <template #default="{ item }">
                            <div class="value">{{ item.name }}</div>
                        </template>
                    </el-autocomplete>
                </base-input>
            </div>
            <div v-if="stepSendMail === 2" class="col-12 d-flex flex-column">
                <p>{{$t('confirmSendMailName',[selectedTemplate.name, contactsToSend.length])}}</p>
                <base-button @click="showPreview = true" link type="link" class="pl-0 text-left btn-email-link">{{$t('seeMailPreview')}}</base-button>
            </div>
        </card>
    </div>
    <div class="col-12 d-flex justify-content-around px-0">
        <base-button
        borderRadiusLeft
        borderRadiusRight
        class="py-2 px-4"
        :disabled="isLoading"
        @click="$emit('endSendMail', false)"
        >{{ $t('cancel') }}
        </base-button>
        <base-button
        borderRadiusLeft
        borderRadiusRight
        class="py-2 px-4"
        :disabled="selectedTemplate === null || isLoading"
        @click="nextStep"
        >
        <span v-if="stepSendMail === 1">
            {{ $t('next') }}
        </span>
        <span v-else class="d-flex">
            <clip-loader v-if="isLoading" color="#ffffff" size="15px" class="pr-2"></clip-loader>
            <span > {{ $t('send') }} </span>
        </span>
        </base-button>
    </div>
</div>
<div v-else>
     <div class="col-12 p-4">
        <div class="d-flex flex-column justify-content-center">
            <h3>{{ $t('mailSentTo', [contactsSent]) }}</h3>
            <template v-if="unsentContacts > 0">
                <p>{{ $t('mailAlreadySentTo', [unsentContacts]) }}</p>
                <small>
                    {{ $t('checkMailsSent') }} 
                    <base-button @click="goToEmailsSents()" link type="link" class="pl-0 text-left btn-email-link">{{selectedTemplate.name}}</base-button>
                </small>
            </template>
        </div>
        <div class="col-12 d-flex justify-content-center px-0">
        <base-button
        borderRadiusLeft
        borderRadiusRight
        class="py-2 px-4"
        :disabled="isLoading"
        @click="$emit('endSendMail', true)"
        >{{ $t('ok') }}
        </base-button>
    </div>
    </div>
</div>
<modal  v-model:show="showPreview" :showClose="true" :is-allowed-close-modal="true" @close="showPreview= false" header-classes="pb-1" body-classes="pt-1">
    <template v-slot:header>
    </template>
    <TemplatePreview v-if="templatePreview" :template="templatePreview" ></TemplatePreview>
</modal>
</template>

<script>
import useMails from '@/composables/useMails';
import { ElAutocomplete } from 'element-plus';
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import modal from '@/components/Modal';
import { TemplateFormClass } from '@/util/Models/TemplateClass.js';
import TemplatePreview from '@/views/Components/TemplatePreview.vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { computed } from 'vue';
import router from '../../../../../router';


export default {
  name: "SendMail",
  props: ["contacts"],
  components: {
    ElAutocomplete,
    modal,
    TemplatePreview,
    ClipLoader
  },
  emits:["endSendMail"],
  setup(props, {emit}) {
    const { getAllMails, allMails, getAllContacts, sendMail }= useMails();
    const isLoading = ref(false)
    const isSent = ref(false)
    const queryAutocomplete = ref(null)
    const selectedTemplate = ref(null)
    const stepSendMail = ref(1);
    const contactsToSend= ref([])
    const contactsSent= ref(null)
    const showPreview = ref(false)
    const templatePreview = ref(null)

    const unsentContacts = computed(()=>{
        if(contactsSent.value !== null){
            return props.contacts.length - contactsSent.value
        }else{
            0            
        }
    })

    const querySearch = async(queryString, cb)=>{
        if(!queryString){
            cb(allMails.value)
        }else{
            cb(allMails.value.filter(item => item.name.includes(queryString)))
        }

    }
    const selectTemplate = (option) => {
        if(option){
            templatePreview.value = new TemplateFormClass(option)
        }
        selectedTemplate.value = option ? option : null;
    }
    const nextStep = async() => {
        if(stepSendMail.value === 2){
            isLoading.value= true
            const form = {
                template: selectedTemplate.value.id,
                contacts: contactsToSend.value.map(item => item.id)
            }
            let response = await sendMail(form)
            if(response && response.status === 200){
                contactsSent.value = response.data.length
                isSent.value= true;
            }
            isLoading.value= false
            return
        }
        stepSendMail.value = stepSendMail.value + 1;

    }

    const goToEmailsSents = ()=>{
        emit('endSendMail', true)
        router.push({name: 'EmailsSent', params:{ id: selectedTemplate.value.id} })
    }

    onMounted(async()=>{
        isLoading.value= true;
        await getAllMails();
        let contacts = props.contacts;
        if(props.contacts.length === 0){
            let response = await getAllContacts()
            contacts = response.data
        }
        contactsToSend.value = contacts;

        isLoading.value= false;
    })

    return {
        
        isLoading,
        isSent,
        queryAutocomplete,
        selectedTemplate,
        stepSendMail,
        contactsToSend,
        contactsSent,
        showPreview,
        templatePreview,
        unsentContacts,
        querySearch,
        nextStep,
        selectTemplate,
        goToEmailsSents
    };
  },
};
</script>

<style scoped lang="scss">

:deep().el-autocomplete{
    width: 100%!important;
    font-weight: 400;
    line-height: 1.5;
    .el-input__inner{
        height: 34px !important;
        padding: 0px 10px !important;
        border: 1px solid #c6dcf5 !important;
        border-radius: 5px !important;
        background-color: #ffffff !important;
        font-size: 13px !important;
        color: #2b2b2d !important;
        -webkit-text-decoration: none solid #2b2b2d !important;
        text-decoration: none solid #2b2b2d !important;
        box-shadow: inset 0 1px 3px rgba(1 ,36 ,84 , 30%) !important;
    }
}
.btn-email-link:hover{
    border-color: transparent;
    background-color: transparent;
    text-decoration: underline;
}

</style>
